<template>
    <div class="inner_pages">
        <div class="respective_content">
            <div class="global_setting">
                <ul class="tabs_btn" v-if="tab && !user.company_id">
                    <li :class="tab === 'account' ? 'active' : ''" @click="tab = 'account'">Account</li>
                    <li :class="tab === 'plans' ? 'active' : ''" @click="tab = 'plans'">Subscription</li>
                    <li :class="tab === 'payment' ? 'active' : ''" @click="tab = 'payment'">Payment Method</li>
                    <li :class="tab === 'billing' ? 'active' : ''" @click="tab = 'billing'">Billing History</li>
                </ul>
                <div class="tabs_content">
                    <div class="content_area" v-if="tab === 'account'">
                          <div class="content_wpr">
                              <div class="section_content w-100">
                                  <div class="section_header">
                                      <h2>
                                          Account<span>Payment action required.</span>
                                      </h2>
                                  </div>
                                  <div class="plan_container" v-if="activateAccountLoader">
                                      <div class="subscription_wpr">
                                          <div class="subscription">
                                              <div class="header">
                                                  Payment Successful
                                              </div>
                                              <div class="features">
                                                  <p class="mb-5">This payment was already successfully confirmed.</p>
                                                  <div class="more_ft">
                                                      <button type="button" class="btn" disabled>
                                                          <i class="fa fa-spin fa-spinner"></i>&nbsp; Activating Account
                                                      </button>
                                                  </div>
                                              </div>
                                          </div>
                                      </div>
                                  </div>
                                  <div class="plan_container" v-else>
                                      <div class="subscription_wpr">
                                          <div class="subscription">
                                              <div class="header">
                                                  Attention Needed
                                              </div>
                                              <div class="features" v-if="user.company_id">
                                                  <p class="mb-5">Your account has been suspended due to non-payment. Please ask your organization to update payment method on file to reinstate your account.</p>
                                              </div>
                                              <div class="features" v-else>
                                                  <p class="mb-5">Your account has been suspended due to non-payment. Please update your payment method on file to reinstate your account.</p>
                                                  <div class="more_ft">
                                                      <button type="button" class="btn" :disabled="paymentIntentLoader" @click="toggleCardAdd()">
                                                          <i class="fa fa-spin fa-spinner" v-if="paymentIntentLoader"></i>&nbsp; {{ paymentIntentLoader ? 'Initializing' : 'Update Payment Method' }}
                                                      </button>
                                                  </div>
                                              </div>
                                          </div>
                                      </div>
                                  </div>
                              </div>
                          </div>
                    </div>
                    <subscription-component v-if="tab === 'plans'" />
                    <payment-method v-if="tab === 'payment'" />
                    <billing-history class="billing-history" v-if="tab === 'billing'" />
                </div>
            </div>
        </div>
        <div class="modal secondary security card-form" v-if="addNewCard">
            <div class="modal_container">
                <h3 class="sub_header m-0" v-if="paymentIntent && paymentIntent.requiresPaymentMethod">
                    Confirm your {{ paymentIntent.amount }} payment
                </h3>
                <div class="setting_wpr">
                    <div v-if="paymentIntentLoader" class="card-loader">
                        <quote-loader />
                    </div>
                    <Form @submit="confirmCardPayment" v-slot="{ errors }" class="profile-form" v-else-if="paymentIntent && !paymentIntentLoader">
                        <label v-show="paymentIntent.requiresPaymentMethod">Extra confirmation is needed to process your payment. Please confirm your payment by filling out your payment details below.</label>
                        <div class="form_grp" v-show="paymentIntent.requiresPaymentMethod">
                            <div class="group_item">
                                <label class="input_label">Name on Card</label>
                                <div class="field_wpr" :class="{ 'has-error': errors.name }">
                                    <Field type="text" :autocomplete="'nofill'" name="name" v-model="cardForm.name" rules="required" placeholder="Enter Cardholder Name" />
                                </div>
                                <ErrorMessage name="name" class="text-danger" />
                            </div>
                        </div>
                        <div class="form_grp" v-show="paymentIntent.requiresPaymentMethod">
                            <div class="group_item">
                                <label class="input_label">Card Number</label>
                                <div class="field_wpr" :class="{ 'has-error': errors.card_number }">
                                    <Field autocomplete="off" type="text" name="card_number" v-model="cardForm.card_number">
                                        <div id="card-element"></div>
                                    </Field>
                                </div>
                                <ErrorMessage name="card_number" class="text-danger" />
                            </div>
                        </div>
                        <div class="action_wpr" v-show="paymentIntent.requiresPaymentMethod || paymentIntent.requiresConfirmation || paymentIntent.requiresAction">
                            <button :disabled="addCardLoader" type="button" class="btn cancel_btn" @click="addNewCard = false">Cancel</button>
                            <button :disabled="addCardLoader" class="btn save_btn" v-if="paymentIntent.requiresPaymentMethod">
                                <i class="fa fa-spinner fa-spin" v-if="addCardLoader"></i>&nbsp;
                                {{ addCardLoader ? 'Paying' : `Pay ${paymentIntent.amount}` }}
                            </button>
                            <button type="button" :disabled="addCardLoader" @click="confirmPaymentMethod()" class="btn save_btn" v-if="paymentIntent.requiresConfirmation || paymentIntent.requiresAction">
                                <i class="fa fa-spinner fa-spin" v-if="addCardLoader"></i>&nbsp;
                                {{ addCardLoader ? 'Confirming' : `Confirm your ${paymentIntent.amount} payment` }}
                            </button>
                        </div>
                    </Form>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import { defineAsyncComponent } from 'vue'
    import { Field, Form, ErrorMessage } from 'vee-validate'
    import { mapState, mapActions } from 'vuex'

    const PaymentMethod = defineAsyncComponent(() => import('@/pages/admin-setting/components/account/billing/PaymentMethod'))
    const BillingHistory = defineAsyncComponent(() => import('@/pages/admin-setting/components/account/billing/BillingHistory'))
    const SubscriptionComponent = defineAsyncComponent(() => import('@/pages/admin-setting/components/account/billing/Subscription'))

    import axios from '@/services/axios'
    import Toastr from '@/utils/Toastr'
    import Helper from '@/utils/Helper'
    import Swal from 'sweetalert2'

    const stripeInstance = window.Stripe(process.env.VUE_APP_STRIPE_KEY, {});

    export default {
        name: 'Payment Action Required',

        data () {
            return {
                tab: 'account',
                addNewCard: false,
                addCardLoader: false,
                cardForm: {},
                billingApi: process.env.VUE_APP_BILLING_API_URL,
                paymentIntent: null,
                paymentIntentLoader: false,
                activateAccountLoader: false,
                pendingInvoices: [],
            }
        },

        components: {
            SubscriptionComponent,
            PaymentMethod,
            BillingHistory,
            Field,
            Form,
            ErrorMessage
        },

        computed: {
            ...mapState({
                user: state => state.authModule.user,
                plansLoader: state => state.billingModule.plansLoader,
            }),

            stripeElements () {
                return stripeInstance.elements();
            },
        },

        mounted () {
            const vm = this;

            vm.getPlans();

            if (vm.$route.query.session_id) {
                vm.retrieveSession(vm.$route.query.session_id);
            }
        },

        beforeUnmount () {
            const vm = this;

            if (vm.card && !vm.card._destroyed) {
                vm.card.destroy();
            }
        },

        methods: {
            ...mapActions({
                refreshAuth: 'authModule/refreshAuth',
                getPlans: 'billingModule/getPlans',
            }),

            toggleCardAdd () {
                const vm = this;

                vm.createPaymentIntent();
            },

            async confirmCardPayment (form, { setFieldError }) {
                const vm = this;
                vm.addCardLoader  = true;

                const { paymentIntent, error } = await stripeInstance.confirmCardPayment(
                    vm.paymentIntent.clientSecret, {
                        payment_method: {
                            card: vm.card,
                            billing_details: { name: form.name }
                        }
                    }
                );

                if (error) {
                    vm.addCardLoader = false;
                    setFieldError('card_number', error.message);
                } else {
                    vm.addCardLoader = true;

                    axios.post(`${vm.billingApi}/stripe/update-billing`, { paymentIntent }, {
                        headers: {
                            Accept: 'application/json',
                            Authorization: `Bearer ${vm.user.access_token}`,
                        },
                    }).then((resp) => {
                        if (vm.card) {
                            vm.card.destroy();
                        }

                        vm.addNewCard = false;
                        vm.addCardLoader = false;
                        vm.paymentIntent = null;

                        Toastr.success('Payment has been verified successfully!');
                    }).catch((err) => {
                        vm.addNewCard = false;
                        vm.addCardLoader = false;
                        Toastr.error('Looks like something went wrong, please try after some time!');
                    });
                }
            },

            createPaymentIntent () {
                const vm = this;

                vm.paymentIntentLoader = true;

                axios.post(`${vm.billingApi}/stripe/create-payment-intent`, { }, {
                    headers: {
                        Accept: 'application/json',
                        Authorization: `Bearer ${vm.user.access_token}`,
                    },
                }).then((resp) => {

                    if (resp.data.object == 'checkout.session') {
                        vm.addNewCard = false;
                        vm.paymentIntentLoader  = false;
                        window.location.href    = resp.data.url;
                    } else {
                        vm.addNewCard = true;
                        vm.paymentIntent        = resp.data;
                        vm.paymentIntentLoader  = false;

                        setTimeout(function () {
                            vm.initializeStripe();
                        }, 10);
                    }
                }).catch((err) => {
                    vm.paymentIntentLoader = false;
                    vm.addNewCard = false;

                    const options = Helper.swalWarningOptions('Oops!', 'Looks like something went wrong, please try after some time.');

                    Swal.fire(options);
                });
            },

            initializeStripe () {
                const vm = this;

                const style = {
                    base: {
                        color: '#5a5a5a',
                        lineHeight: '50px',
                        fontSmoothing: 'antialiased',
                        fontSize: '14px',
                        '::placeholder': {
                            color: '#aab7c4',
                        },
                    },
                    invalid: {
                        color: '#eb1414',
                        iconColor: '#eb1414',
                    },
                };

                vm.card = vm.stripeElements.create('card', { hidePostalCode: true, style });
                vm.card.mount('#card-element');
            },

            async confirmPaymentMethod () {
                const vm = this;

                vm.addCardLoader  = true;

                const { paymentIntent, error } = await stripeInstance.confirmCardPayment(
                    vm.paymentIntent.clientSecret, {
                        payment_method: vm.paymentIntent.payment_method,
                    }
                );

                if (error) {
                    vm.addCardLoader = false;

                    if (error.code == 'payment_intent_authentication_failure') {
                        vm.paymentIntent.requiresPaymentMethod = true;
                        vm.paymentIntent.requiresAction = false;
                        vm.initializeStripe();
                    }
                } else {
                    vm.addCardLoader = true;

                    axios.post(`${vm.billingApi}/stripe/update-billing`, { paymentIntent }, {
                        headers: {
                            Accept: 'application/json',
                            Authorization: `Bearer ${vm.user.access_token}`,
                        },
                    }).then((resp) => {
                        if (vm.card) {
                            vm.card.destroy();
                        }

                        vm.addNewCard = false;
                        vm.addCardLoader = false;
                        vm.paymentIntent = null;

                        Toastr.success('Payment has been verified successfully!');
                    }).catch((err) => {
                        vm.addNewCard = false;
                        vm.addCardLoader = false;
                        Toastr.error('Looks like something went wrong, please try after some time!');
                    });
                }
            },

            retrieveSession (sessionId) {
                const vm = this;
                vm.activateAccountLoader = true;

                axios.get(`${vm.billingApi}/stripe/${sessionId}/session-retrieve`, {}, {
                    headers: {
                        Accept: 'application/json',
                        Authorization: `Bearer ${vm.user.access_token}`,
                    },
                }).then((resp) => {
                    vm.activateSuspendedAccount(resp.data.subscription).then((result) => {
                        if (result) {
                            vm.refreshAuth();
                        }

                        vm.activateAccountLoader = false;
                    });
                }).catch((err) => {
                    vm.activateAccountLoader = false;
                });
            },

            activateSuspendedAccount (subscription) {
                const vm = this;

                return new Promise(function(resolve, reject) {
                    axios.post(`${vm.billingApi}/stripe/activate-account`, { subscription }, {
                        headers: {
                            Accept: 'application/json',
                            Authorization: `Bearer ${vm.user.access_token}`,
                        },
                    }).then((resp) => {
                        if (resp) {
                            resolve(true);
                        }
                    }).catch((err) => {
                        resolve(false);
                    });
                });
            },
        },
    };
</script>

<style scoped>
    .respective_content {
        padding: 0;
    }

    .loader-wpr {
        width: 100%;
        height: 80vh;
        display: flex;
        align-content: center;
        justify-content: center;
        align-items: center;
    }

    .global_setting {
        height: 78vh;
    }

    :deep(.forms-setting-form) {
        display: flex;
        flex-direction: column;
        height: inherit;
    }

    :deep(.section_header h2 span) {
        font-size: 13px;
        line-height: 17px;
        color: #5a5a5a;
        font-weight: 500;
        display: block;
        padding-top: 10px;
    }

    :deep(.billing .setting_wpr) {
        max-width: 600px;
    }

    :deep(.billing .setting_wpr .group_item .input_label) {
        font-weight: 500;
    }

    :deep(.billing .form_grp h4) {
        font-size: 18px;
        line-height: 23px;
        color: #5a5a5a;
        font-weight: 300;
        margin-top: 12px;
    }

    :deep(.profile_top) {
        display: flex;
        align-items: center;
        justify-content: space-between;
        border-bottom: 1px solid #eee;
        padding: 20px 0;
    }

    :deep(.user_img) {
        display: flex;
        align-items: center;
        gap: 15px;
        position: relative;
    }

    :deep(.user_img img) {
        width: 70px;
        height: 70px;
        border-radius: 50%;
        object-fit: cover;
        border: 1px solid #eee;
    }

    :deep(.user_img button) {
        width: 20px;
        height: 20px;
        font-size: 8px;
        border-radius: 50%;
        background: #fff;
        border: 1px solid #dbdbdb;
        color: #2f7eed;
        position: absolute;
        right: 0;
        bottom: 0;
        cursor: pointer;
    }

    :deep(.user_img .username button) {
        border: 1px solid #2f7eed;
        padding: 7px 20px;
        border-radius: 15px;
        color: #2f7eed;
        font-size: 13px;
        line-height: 16px;
        font-weight: 500;
        font-family: 'Inter', sans-serif;
        cursor: pointer;
    }

    :deep(.field_wpr) {
        border: 1px solid #dfdfdf;
    }

    :deep(.field_wpr input),
    :deep(.field_wpr select),
    :deep(.field_wpr textarea) {
        border-radius: 5px;
    }

    :deep(.point_header) {
        font-size: 18px;
        line-height: 23px;
        font-weight: 500;
        color: #121525;
        margin: 30px 0 10px;
        display: flex;
        align-items: center;
    }

    :deep(.point_header i) {
        margin-right: 10px;
        color: #999;
    }

    :deep(.para) {
        font-size: 13px;
        line-height: 19px;
        font-weight: 400;
        color: #5a5a5a;
    }

    :deep(.change_box) {
        padding: 20px 0 30px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        font-size: 18px;
        line-height: 21px;
        font-weight: 300;
        color: #121525;
    }

    :deep(.change_box button) {
        font-size: 13px;
        line-height: 16px;
        color: #2f7eed;
        font-weight: 500;
        padding: 5px 15px;
        border: 1px solid #84b7ff;
        border-radius: 5px;
        cursor: pointer;
    }

    :deep(.billing_card) {
        background: #fff;
        border-radius: 8px;
        box-shadow: 0 4px 20px rgba(0, 0, 0, 0.05);
        margin: 20px 0 30px;
    }

    :deep(.billing_card .header) {
        padding: 12px 30px;
        font-size: 15px;
        line-height: 20px;
        font-weight: 500;
        color: #121525;
        border-bottom: 1px solid #eee;
    }

    :deep(.billing_card .card_body) {
        padding: 20px 30px;
    }

    :deep(.billing_card .card_body p) {
        font-size: 13px;
        line-height: 18px;
        color: #5a5a5a;
        margin-bottom: 20px;
    }

    :deep(.billing_card .card_body button) {
        font-size: 13px;
        line-height: 16px;
        background: #2f7eed;
        color: #fff;
        font-weight: 500;
        padding: 8px 20px;
        border-radius: 5px;
        cursor: pointer;
        margin-bottom: 10px;
        text-transform: uppercase;
        font-family: 'Inter', sans-serif;
    }

    :deep(.billing_card .card_body button.delete_btn) {
        background: transparent;
        color: #eb1414;
        border: 1px solid #eb1414;
        transition: all 0.3s ease-in-out;
    }

    :deep(.billing_card .card_body button.delete_btn.disable) {
        cursor: no-drop;
    }

    :deep(.billing_card .card_body button.delete_btn.active) {
        background: #eb1414;
        color: #fff;
    }

    :deep(.plan_container) {
        display: flex;
        flex-wrap: wrap;
        gap: 40px;
        align-items: flex-start;
    }

    :deep(.subscription_wpr) {
        width: 55%;
        min-width: 400px;
        display: flex;
        flex-direction: column;
        margin: 20px 0 0;
    }

    :deep(.subscription) {
        background: #fff;
        border-radius: 8px;
        box-shadow: 0 4px 20px rgba(0, 0, 0, 0.05);
        margin-bottom: 30px;
    }

    :deep(.subscription .header) {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 15px 30px;
        border-bottom: 1px solid #eee;
    }

    :deep(.subscription .header h3) {
        font-size: 20px;
        line-height: 27px;
        font-weight: 500;
        color: #121525;
        display: flex;
        align-items: center;
    }

    :deep(.subscription .header .price),
    :deep(.addOn_card .price) {
        font-size: 22px;
        line-height: 27px;
        font-weight: 500;
        color: #121525;
        display: flex;
    }

    :deep(.subscription .header .price span.sub),
    :deep(.addOn_card .price span.sub) {
        font-size: 13px;
        line-height: 16px;
        align-self: flex-end;
        padding: 0 0 3px 3px;
    }

    :deep(.subscription .footer) {
        padding: 10px 30px;
        border-top: 1px solid #eee;
    }

    :deep(.subscription .features) {
        padding: 20px 30px;
        position: relative;
    }

    :deep(.subscription .features ul li) {
        font-size: 13px;
        line-height: 18px;
        font-weight: 400;
        color: #5a5a5a;
        padding: 6px 0;
        display: flex;
        flex-wrap: wrap;
    }

    :deep(.subscription .features ul li i) {
        margin-right: 10px;
        font-size: 8px;
        color: #fff;
        width: 16px;
        height: 16px;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        background: rgba(117, 210, 24, 0.7);
    }

    :deep(button.btn) {
        font-size: 12px;
        line-height: 15px;
        background: #2f7eed;
        text-transform: uppercase;
        color: #fff;
        font-weight: 500;
        padding: 10px 25px;
        border-radius: 20px;
        cursor: pointer;
        font-family: 'Inter', sans-serif;
    }

    :deep(.subscription .features h4) {
        font-size: 14px;
        line-height: 20px;
        color: #121525;
        font-weight: 500;
        margin: 15px 0;
        cursor: pointer;
        display: flex;
        align-items: center;
    }

    :deep(.subscription .features h4 i) {
        font-size: 13px;
        margin-left: 10px;
    }

    :deep(.subscription .features .more_ft) {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        align-items: flex-end;
    }

    :deep(.subscription .features .more_ft ul) {
        max-height: 0;
        overflow: hidden;
        transition: all 0.3s ease-in-out;
    }

    :deep(.subscription .features .more_ft ul.active) {
        max-height: 500px;
    }

    :deep(.subscription .features a) {
        font-size: 11px;
        line-height: 14px;
        color: #5a5a5a;
        font-weight: 500;
        padding: 10px 20px;
        background: #eee;
        border-radius: 17px;
        cursor: default;
        display: inline-block;
    }

    :deep(.subscription .header h3 .discount) {
        font-size: 11px;
        line-height: 14px;
        font-weight: 500;
        background: #fdca72;
        border-radius: 11px;
        color: #121525;
        padding: 4px 10px;
        margin-left: 10px;
    }

    :deep(.addOns_Wpr) {
        margin: 20px 0 40px;
        width: 30%;
        max-width: 400px;
        min-width: 300px;
        display: flex;
        flex-direction: column;
    }

    :deep(.addOns_Wpr .upgrade_area) {
        position: sticky;
        top: 0;
        left: 0;
        background: #fafafb;
        padding: 15px 0;
        z-index: 3;
        margin: 0 -15px;
        padding: 15px;
    }

    :deep(.subscription_wpr .result_wpr table td p) {
        font-weight: 400;
    }

    :deep(.subscription_wpr .result_wpr table td:first-child),
    :deep(.subscription_wpr .result_wpr table th:first-child) {
        width: auto;
        text-align: left;
        padding: 20px 15px;
    }

    :deep(.billing_history .result_wpr table td:last-child),
    :deep(.billing_history .result_wpr table th:last-child) {
        text-align: center;
    }

    :deep(.table_title) {
        font-size: 18px;
        line-height: 25px;
        padding: 15px 20px;
        background: #fff;
        color: #121525;
        font-weight: 500;
        border-radius: 14px 14px 0 0;
        border-bottom: 1px solid #eee;
    }

    :deep(.upgrade_area) {
        display: flex;
        justify-content: space-between;
        align-items: center;
        max-width: 800px;
        margin-bottom: 25px;
    }

    :deep(.upgrade_area .price) {
        font-size: 20px;
        line-height: 25px;
        font-weight: 500;
        color: #2f7eed;
        margin-bottom: 10px;
    }

    :deep(.upgrade_area .price span.sub) {
        font-size: 13px;
        line-height: 16px;
        align-self: flex-end;
        padding: 0 0 3px 3px;
    }

    :deep(.upgrade_area h3) {
        font-size: 20px;
        line-height: 25px;
        color: #121525;
        font-weight: 500;
    }

    :deep(.plan_type) {
        display: flex;
        font-size: 14px;
        line-height: 17px;
        align-items: center;
        gap: 10px;
        font-weight: 500;
        color: #121525;
    }

    :deep(.payment_section ){
        max-width: 800px;
        margin-top: 30px;
    }

    :deep(.paymentcard_wpr) {
        display: flex;
        flex-wrap: wrap;
        gap: 20px;
        margin-top: 30px;
    }

    :deep(.payment_card) {
        padding: 20px;
        border-radius: 8px;
        background: #fff;
        flex: 0 1 300px;
        box-shadow: 0 4px 20px rgba(0, 0, 0, 0.05);
        position: relative;
    }

    :deep(.payment_card .top_col) {
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
        margin-bottom: 20px;
    }

    :deep(.payment_card .primary_selection) {
        display: flex;
        align-items: center;
        font-size: 11px;
        line-height: 14px;
        font-weight: 500;
        color: #121525;
        margin-top: 5px;
    }

    :deep(.payment_card label.checkbox input[type=radio]:checked~span i) {
        transform: scale(1);
        opacity: 1;
    }

    :deep(.payment_card label.checkbox input[type=radio]:checked~.active_line) {
        border: 1px solid rgba(47, 126, 237, 0.25);
        border-radius: 8px;
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
    }

    :deep(.payment_card img.chip) {
        height: 30px;
        width: auto;
    }

    :deep(.payment_card .card_number) {
        font-size: 15px;
        line-height: 18px;
        letter-spacing: 3px;
        margin-bottom: 20px;
    }

    :deep(.payment_card .card_name) {
        font-size: 11px;
        line-height: 14px;
        text-transform: uppercase;
        letter-spacing: 1px;
        display: flex;
        align-items: flex-end;
        justify-content: space-between;
    }

    :deep(.payment_card .card_name h6) {
        font-size: 11px;
        line-height: 13px;
        font-weight: 500;
        display: flex;
        justify-content: space-between;
        margin-bottom: 10px;
    }

    :deep(.payment_card .card_name img.type) {
        max-height: 40px;
        width: auto;
    }

    :deep(button.add_card) {
        font-size: 13px;
        line-height: 16px;
        font-weight: 500;
        color: #2f7eed;
        padding: 10px 20px;
        background: #fff;
        border: 1px solid #2f7eed;
        border-radius: 5px;
        text-transform: uppercase;
        font-family: 'Inter', sans-serif;
    }

    :deep(.infocard_wpr) {
        display: flex;
        flex-wrap: wrap;
        gap: 20px;
        margin: 30px 0;
    }

    :deep(.info_card) {
        background: #fff;
        border-radius: 8px;
        flex: 0 1 390px;
        box-shadow: 0 4px 20px rgba(0, 0, 0, 0.05);
    }

    :deep(.info_card .header) {
        padding: 10px 20px;
        border-bottom: 1px solid #eee;
        font-size: 15px;
        line-height: 20px;
        font-weight: 400;
        color: #121525;
    }

    :deep(.info_card .info_body) {
        padding: 20px;
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    :deep(.info_card .info_body img) {
        height: 80px;
        width: auto;
    }

    :deep(.info_card h3) {
        font-size: 20px;
        line-height: 25px;
        color: #121525;
        font-weight: 500;
        margin-bottom: 10px;
    }

    :deep(.info_card h6) {
        font-size: 11px;
        line-height: 14px;
        color: #5a5a5a;
        font-weight: 500;
        margin-bottom: 10px;
    }

    :deep(.info_card .price) {
        font-size: 24px;
        line-height: 30px;
        font-weight: 500;
        color: #121525;
        margin-bottom: 10px;
    }

    :deep(.info_card .discount) {
        font-size: 11px;
        line-height: 14px;
        font-weight: 500;
        background: #fff4e0;
        border-radius: 11px;
        color: #121525;
        padding: 10px;
        display: flex;
        flex-direction: column;
        flex: 0 0 130px;
        justify-content: center;
        align-items: center;
    }

    :deep(.info_card .discount label) {
        font-size: 18px;
        line-height: 25px;
        color: #2f7eed;
        font-weight: 600;
        border-bottom: 1px solid #c9c9c9;
        padding-bottom: 3px;
        margin-bottom: 5px;
        position: relative;
    }

    :deep(.info_card .discount label small) {
        font-size: 9px;
        line-height: 11px;
        color: #121525;
        position: absolute;
        left: 50%;
        bottom: -5px;
        transform: translateX(-50%);
        background: #fff4e0;
        padding: 0 5px;
    }

    :deep(.info_card .discount label span) {
        font-size: 11px;
        line-height: 15px;
        color: #121525;
        font-weight: 600;
    }

    :deep(.billing_history) {
        width: 50%;
        min-width: 550px;
    }

    :deep(.billing_history .result_wpr table td) {
        font-weight: 400;
    }

    :deep(.billing_history .result_wpr table td p) {
        font-weight: 500;
    }

    :deep(.billing_history .result_wpr table td:first-child),
    :deep(.billing_history .result_wpr table th:first-child) {
        width: auto;
        text-align: left;
        padding: 20px 15px;
    }

    :deep(.billing_history .result_wpr table td .status) {
        background: rgba(117, 210, 24, 0.2);
        font-size: 11px;
        line-height: 14px;
        color: #5a5a5a;
        font-weight: 500;
        padding: 4px 12px;
        border-radius: 11px;
    }

    :deep(.billing_history .result_wpr table td .status i) {
        margin-right: 4px;
        width: auto;
        font-size: 8px;
    }

    :deep(.addOn_card) {
        border-radius: 8px;
        background: #fff;
        max-width: 400px;
        box-shadow: 0 4px 20px rgba(0, 0, 0, 0.05);
        margin-bottom: 30px;
    }

    :deep(.addOn_card .header) {
        padding: 15px 20px;
        border-bottom: 1px solid #eee;
        display: flex;
        gap: 5px;
        flex-wrap: wrap;
        justify-content: space-between;
        align-items: center;
    }

    :deep(.addOn_card .header h4) {
        font-size: 14px;
        line-height: 20px;
        font-weight: 500;
        color: #121525;
    }

    :deep(.addOn_card .header h4 .discount) {
        font-size: 10px;
        line-height: 12px;
        font-weight: 500;
        background: #fdca72;
        border-radius: 11px;
        color: #121525;
        padding: 3px 8px;
        margin-left: 10px;
    }

    :deep(.addOn_card .addOn_info) {
        padding: 20px;
        display: flex;
        flex-direction: column;
    }

    :deep(.addOn_card .header button) {
        font-size: 10px;
        line-height: 12px;
        background: #2f7eed;
        color: #fff;
        font-weight: 400;
        padding: 5px 10px;
        border-radius: 3px;
        cursor: pointer;
        text-transform: uppercase;
        font-family: 'Inter', sans-serif;
    }

    :deep(.addOn_info .extra_wpr) {
        margin-top: 20px;
    }

    :deep(.addOn_info .extra_wpr .switch_option h5) {
        font-size: 13px;
    }

    :deep(.range_slider) {
        width: 100%;
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: center;
        margin: 30px 0 15px;
    }

    :deep(.range_slider.no-move) {
        pointer-events: none;
        filter: grayscale(1);
    }

    :deep(.range_slider .range_label) {
        display: flex;
        align-items: center;
        justify-content: space-between;
        font-size: 9px;
        line-height: 11px;
        font-weight: 500;
        color: #5a5a5a;
        margin: 10px 0 0;
    }

    :deep(.range_slider span) {
        height: 4px;
        background: #2f7eed;
        position: absolute;
        top: 0;
        border-radius: 3px;
    }

    :deep(.range_slider span label) {
        position: absolute;
        right: 0;
        transform: translateX(50%);
        bottom: 100%;
        padding: 1px 4px;
        background: #2f7eed;
        border-radius: 3px;
        font-size: 9px;
        line-height: 13px;
        color: #fff;
        font-weight: 400;
        box-shadow: 0 1px 5px rgba(0, 0, 0, 0.2);
        display: inline-block;
        margin-bottom: 12px;
    }

    :deep(.range_slider span label:after) {
        position: absolute;
        content: '';
        border-top: 4px solid #2f7eed;
        border-right: 4px solid transparent;
        border-left: 4px solid transparent;
        top: 100%;
        left: 50%;
        transform: translateX(-50%);
    }

    :deep(.range_slider .slider) {
        -webkit-appearance: none;
        appearance: none;
        width: 100%;
        height: 4px;
        border-radius: 2px;
        background: rgba(59, 126, 241, 0.3);
        outline: none;
        margin: 0;
        position: relative;
    }

    :deep(.range_slider .slider::-webkit-slider-thumb) {
        -webkit-appearance: none;
        appearance: none;
        width: 15px;
        height: 15px;
        border: 1px solid #2f7eed;
        background: #2f7eed;
        cursor: pointer;
        border-radius: 50%;
        position: relative;
        z-index: 2;
    }

    :deep(.range_slider .slider::-moz-range-thumb) {
        width: 15px;
        height: 15px;
        border: 1px solid #2f7eed;
        background: #2f7eed;
        cursor: pointer;
        border-radius: 50%;
    }

    :deep(.no-portal-chat) {
        height: calc(100vh - 315px);
        background: #fff;
        border: 1px solid #eee;
        border-radius: 10px;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 15px;
    }

    :deep(.no-portal-chat h2) {
        font-size: 20px;
        line-height: 30px;
        max-width: 350px;
        text-align: center;
        color: #999;
        font-weight: 300;
    }

    :deep(.security .setting_wpr) {
        width: 100%;
        padding: 15px;
    }

    :deep(.security .action_wpr) {
        justify-content: center;
        margin: 20px 0 0;
    }

    :deep(.storage) {
        padding: 5px 15px;
        background: #fff;
        border: 1px solid #f5f5f5;
        border-radius: 3px;
        position: relative;
        width: 100%;
        overflow: hidden;
        margin: 10px 0;
    }

    :deep(.storage h4) {
        font-size: 10px;
        line-height: 12px;
        color: #757575;
        font-weight: 500;
        position: relative;
        z-index: 1;
    }

    :deep(.storage span) {
        background: rgba(47, 126, 237, 0.1);
        position: absolute;
        left: 0;
        top: 0;
        bottom: 0;
        z-index: 0;
    }

    :deep(span.lh-7) {
        line-height: 7px;
    }

    :deep(.billing-history .plan_container .billing_history) {
        width: 55%;
        min-width: 55%;
    }

    :deep(.billing-history .plan_container .billing_history .result_wpr table td) {
        padding: 18px 15px;
    }

    :deep(.billing-history .plan_container .invoice_container) {
        width: 40% !important;
    }

    :deep(.billing_card:last-child) {
        margin-bottom: 200px;
    }

    .card-form .paymentcard_wpr {
        display: block;
        margin: 0;
    }

    .card-form .paymentcard_wpr .payment_card {
        padding: 10px;
        margin: 5px 0px;
        background: transparent;
        box-shadow: none;
        border: 1px solid #dfdfdf;
    }

    .card-form .paymentcard_wpr .payment_card.has-error {
        border-color: #eb1414 !important;
    }

    .card-form .paymentcard_wpr .payment_card .price-info {
        display: flex;
        justify-content: space-between;
        width: 100%;
    }

    #card-error {
        color: #eb1414;
    }

    .StripeElement {
        border: 0;
        width: 100%;
        height: 50px;
        font-family: 'Inter', sans-serif;
        font-size: 14px;
        color: #5a5a5a;
        font-weight: 400;
        padding: 0 15px;
        box-sizing: border-box;
        background: transparent;
    }

    :deep(.__PrivateStripeElement),
    :deep(.__PrivateStripeElement iframe) {
        height: 50px !important;
    }

    :deep(.__PrivateStripeElement iframe .InputElement) {
        height: 50px !important;
    }

    .card-loader {
        min-height: 200px;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .paymentcard_wpr .quote_wpr {
        width: 100%;
        min-height: 50vh;
        justify-content: center;
    }

    .billing_history {
        width: 100%;
        min-width: 100%;
    }

    .billing_history .table_title {
        font-size: 13px;
        line-height: 13px;
        padding: 10px 20px;
    }

    .billing_history .result_wpr table td:first-child,
    .billing_history .result_wpr table th:first-child,
    .billing_history .result_wpr table td {
        padding: 10px 15px;
    }
    .billing_history .result_wpr table tfoot td {
        padding: 10px 15px;
        font-weight: 500;
    }
</style>
